import React from "react";
import theme from "theme";
import { Theme, Span, Text, Box, Strong, Link, Image, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { FaUnlockAlt, FaRegClock, FaGift, FaUserTie, FaUserFriends, FaHandshake, FaChartLine, FaUserGraduate } from "react-icons/fa";
import { GiLightProjector } from "react-icons/gi";
import { IoIosChatbubbles } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"sikeres-munkahelyi-kommunikacio"} />
		<Helmet>
			<title>
				Sikeres Munkahelyi Kommunikáció - Online Tanfolyam
			</title>
			<meta name={"description"} content={"Chase Hughes technikáira épülő kommunikációt fejlesztő tanfolyam. Tanuld meg hatékonyan kifejezni magadat, alkalmazz taktikai kérdéseket, melyekkel irányítani tudod a beszélgetést, és érvényesülj minden helyzetben."} />
			<meta property={"og:title"} content={"Sikeres Munkahelyi Kommunikáció - Online Tanfolyam"} />
			<meta property={"og:description"} content={"Chase Hughes technikáira épülő kommunikációt fejlesztő tanfolyam. Tanuld meg hatékonyan kifejezni magadat, alkalmazz taktikai kérdéseket, melyekkel irányítani tudod a beszélgetést, és érvényesülj minden helyzetben. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/portrait-business-team-holding-emojis.jpg?v=2023-12-14T13:01:15.712Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
		</Helmet>
		<Components.GlobalHeaderNavBar overflow-x="hidden" overflow-y="hidden">
			<Override slot="link2" />
		</Components.GlobalHeaderNavBar>
		<Section
			padding="112px 0 75px 0"
			background="linear-gradient(0deg,--color-brandSecondary 0%,rgba(72, 124, 207, 0.8) 49.5%,--color-brandSecondary 100%),url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/neuron-cells-bg.webp?v=2024-02-16T20:42:38.094Z) 3% 82%/cover no-repeat fixed"
			md-padding="96px 0 0px 0"
			sm-padding="72px 0 0px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero"
			margin="0 0 -20px 0"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				margin="0px 64px 20px 64px"
				sm-margin="0px 25px 20px 25px"
			/>
			<Box
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
			>
				<Box min-width="100px" min-height="100px" display="flex">
					<Text
						margin="0px 0px 24px 0px"
						color="--brandLight"
						font="normal 900 3.5em/1.2 --fontFamily-googleLexend"
						lg-text-align="center"
						md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						width="100%"
						hyphens="manual"
						as="h1"
						sm-text-align="left"
						sm-font="normal 900 1.8em/1.2 --fontFamily-googleLexend"
						letter-spacing="1.5px"
					>
						S
						<Span font-weight="normal">
							IKERES{" "}
						</Span>
						M
						<Span font-weight="normal">
							UNKAHELYI{" "}
						</Span>
						K
						<Span font-weight="normal">
							OMMUNIKÁCIÓ
						</Span>
					</Text>
				</Box>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					color="--brandLight"
					font="--lead"
					lg-text-align="center"
					width="100%"
					sm-font="--lead"
					sm-text-align="left"
					letter-spacing="1.5px"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Chase Hughes{" "}
					</Strong>
					technikáira épülő kommunikációt fejlesztő tanfolyam
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--brandLight"
					font="--base"
					lg-text-align="center"
					lg-width="80%"
					letter-spacing="1.3px"
					sm-text-align="left"
					sm-width="90%"
				>
					Korlátozott ideig hozzáférést biztosítunk a "Konfliktuskezelés befolyásolás technikával", illetve a "Toxikus
			Emberek Kiszűrése" modulokhoz is.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="#video"
						padding="10px 24px 10px 24px"
						color="--brandLight"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-brandLight"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						sm-width="60%"
						hover-transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						background="--color-brandCta"
						text-align="center"
						hover-background="--color-orange"
						hover-border-color="--color-brandCta"
					>
						Megnézem a Minta Leckét!
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				sm-display="none"
			>
				<Image
					src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/smk_hero_img.webp?v=2024-02-16T21:01:29.975Z"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
					opacity="0.8"
					srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/smk_hero_img.webp?v=2024-02-16T21%3A01%3A29.975Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/smk_hero_img.webp?v=2024-02-16T21%3A01%3A29.975Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/smk_hero_img.webp?v=2024-02-16T21%3A01%3A29.975Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/smk_hero_img.webp?v=2024-02-16T21%3A01%3A29.975Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/smk_hero_img.webp?v=2024-02-16T21%3A01%3A29.975Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/smk_hero_img.webp?v=2024-02-16T21%3A01%3A29.975Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/smk_hero_img.webp?v=2024-02-16T21%3A01%3A29.975Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			lg-padding="88px 0 88px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 60px 0"
			padding="40px 0 40px 0"
			min-height="100vh"
			quarkly-title="Video"
			id="video"
			overflow-x="hidden"
			overflow-y="hidden"
			background="radial-gradient(circle at 100%,rgba(0, 0, 0, 0.36) 0%,rgba(72, 124, 207, 0.84) 78.6%,--color-brandSecondary 100%),url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/videobg.webp?v=2024-02-14T18:05:57.278Z) center center/cover no-repeat fixed,--color-brandSecondary"
			box-shadow="inset 0 0 0 36px --color-dark"
			sm-box-shadow="inset 0 0 0 10px --color-dark"
			md-box-shadow="inset 0 0 0 18px --color-dark"
			lg-box-shadow="inset 0 0 0 25px --color-dark"
			border-radius="24px"
			z-index="10"
			position="relative"
		>
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				justify-content="center"
				flex-direction="column"
				flex="0 0 auto"
			/>
			<Text margin="0px 0px 24px 0px" color="--light" font="--base">
				MINTALECKE
			</Text>
			<Text
				width="100%"
				lg-width="100%"
				sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				color="--light"
				font="--headline2"
				margin="0px 0px 40px 0px"
				as="h2"
			>
				Próbáld ki díjmentesen!
			</Text>
			<Text
				width="50%"
				lg-width="100%"
				sm-font="--lead"
				color="--light"
				font="--lead"
				margin="0px 0px 40px 0px"
			>
				Ilyen leckékre számíthatsz a tanfolyam során. Indítsd el a videót és tanuld meg miért NE használd a “Beszélnünk
			kell” kifejezést, valamint mit használj helyette, hogy megnyisd a kommunikációs vonalakat!
			</Text>
			<Components.QuarklycommunityKitPopup align-self="center" margin="40px 0px 0px 0px" onloadShow={false}>
				<Override slot="Wrapper" display="block" width="80%" background="--color-brandSecondary" />
				<Override
					slot="Button Close"
					size="40px"
					transition="transform 0.3s ease-in-out 0s"
					hover-transform="rotateZ(180deg)"
					padding="1px 1px 1px 1px"
					color="--brandLight"
				/>
				<Override
					slot="Button Open"
					background="url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/VectorPlay.svg?v=2021-09-13T01:01:57.213Z) 0% 0%/contain no-repeat,linear-gradient(-90deg,--color-brandSecondary 0%,--color-brandCta 55.7%) 0 0 no-repeat"
					font="normal 400 24px/1.5 --fontFamily-googleRoboto"
					color="--light"
					opacity="1"
					transition="transform 0.5s ease-in-out 0.5s,opacity 0.5s ease-in-out 0.3s"
					hover-opacity="0.8"
					padding="3px 8px 6px 58px"
					border-width="2px"
					border-style="solid"
					border-color="rgba(230, 41, 79, 0.6)"
					border-radius="30px 5px 5px 30px"
					hover-background="url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/VectorPlay.svg?v=2021-09-13T01:01:57.213Z) 0% 0%/contain no-repeat,linear-gradient(-90deg,--color-brandCta 0%,--color-brandCta 55.7%) 0 0 no-repeat"
					sm-font="normal 400 18px/1.5 --fontFamily-googleRoboto"
					hover-transition="opacity --transitionDuration-normal ease-in-out 0.3s"
					hover-transform="rotate(5deg)"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						font="--lead"
						sm-font="normal small-caps 400 1.3em/1.5 --fontFamily-googleRubik"
					>
						Mintalecke elindítása
					</Span>
				</Override>
				<Override slot="Popup :closed" display="none" />
				<Override slot="Content" />
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/jb_q4G3gkH0" modestbranding>
					<Override slot="YouTube Content" />
					<Override slot="YouTube Button" />
				</Components.QuarklycommunityKitYouTube>
			</Components.QuarklycommunityKitPopup>
			<Box min-width="100px" min-height="100px">
				<Box
					min-width="100px"
					min-height="100px"
					align-self="center"
					padding="50px 0px 50px 0px"
					display="flex"
					justify-content="center"
					sm-padding="20px 0px 20px 0px"
				>
					<Text
						as="p"
						font="--lead"
						margin="20px 0 20px 0"
						text-align="center"
						color="--brandLight"
						max-width="50%"
						sm-max-width="100%"
						sm-font="--base"
					>
						Töltsd le díjmentesen a 29 oldalas jegyzetet, amely további 12 szó-szerinti technikát és számos munkahelyi
				kommunikációs stratégiát tartalmaz.
					</Text>
				</Box>
				<Components.CtaPopUp sm-width="100%">
					<Override
						slot="Button Open"
						border-width="2px"
						border-style="solid"
						border-color="--color-brandLight"
						background="none"
					>
						Letöltöm a Jegyzeteket
					</Override>
					<Override slot="Popup :closed" />
					<Override slot="Content" />
					<Override slot="Wrapper" />
					<Text margin="0px 0px 0px 0px" font="--lead">
						Feliratkozás után hamarosan küdjük a jegyzeteket.
						<br />
						Kérdésseddel bátran fordulj hozzánk elérhetőségeink bármelyikén.
					</Text>
					<Components.MailChimpSubscribe margin="0px auto 0px auto" listId="869da1a2b1" tagId="5830322">
						<Override slot="button" margin="0px auto 0px auto" />
						<Override slot="text3">
							Az
							<Link
								href="#"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Adatkezelési tájékoztatót
							</Link>
							megismertem és elfogadom, hozzájárulok, hogy hírlevelet küldjetek nekem.
						</Override>
					</Components.MailChimpSubscribe>
				</Components.CtaPopUp>
			</Box>
		</Section>
		<Section
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			quarkly-title="Hogyan zajlik a kurzus"
			overflow-x="hidden"
			overflow-y="hidden"
			background="linear-gradient(0deg,--color-brandLight 0%,rgba(240, 240, 240, 0.7) 40%,--color-brandLight 100%) fixed,--color-brandSecondary"
			justify-content="center"
			margin="-20px 0 0 0"
			position="static"
			display="flex"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
				sm-flex-wrap="wrap-reverse"
				margin="60px 64px 60px 64px"
				sm-margin="60px 25px 0px 25px"
				md-flex-wrap="wrap-reverse"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				opacity="1"
			>
				<Text
					as="p"
					font="--base"
					color="--brandSecondary"
					margin="0px 0px 8px 0px"
					opacity="0.8"
				>
					TANFOLYAM
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--brandSecondary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					border-color="--color-brandPrimary"
				>
					Hogyan zajlik a kurzus?
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="normal 500 17px/1.5 --fontFamily-googleRubik"
					font-weight="300"
					color="--brandPrimary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					border-color="--color-brandPrimary"
				>
					A Sikeres Munkahelyi Kommunikáció tanfolyam egy rugalmas, önálló tanulást támogató online képzés, amely lehetővé
			teszi a résztvevők számára, hogy saját tempójukban haladjanak. A kurzus 9 videó modulból áll, melyek bármikor
			visszanézhetőek.
					<br />
					<br />
					Minden leckéhez részletes jegyzetet adunk. A jobb tudásmegtartás érdekében az oktató videókat játékos gyakorlat és teszt követi, melyek segítenek a tanultak megszilárdításában.
					<br />
					<br />
					A képzést az Oktatási Platformunkon (az{" "}
					<Link
						href="https://edu.sziklaybence.com"
						target="_blank"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--brandSecondary"
						font="--base"
					>
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Akadémián
						</Span>
					</Link>
					) keresztül értheted el. Itt nem csak a tanfolyamhoz kapsz hozzáférést, hanem a "Befolyásolás és Profilozás
			Alapkurzushoz", valamint más díjmentes oktatási anyagokhoz is.
					<br />
					<br />
					A Sikeres Kommunikáció Akadémia mobilalkalmazáson keresztül is elérhető, ahol a tanulás és a közösségépítés összeolvad. Kihívásokkal, játékos gyakorlatokkal és a közösségi tanulás lehetőségével várunk Téged, hogy gyorsabban sajátítsd el a tanultakat.
					<br />
					<br />
					Nem láttad még a
					<Link
						href="#"
						color="--brandSecondary"
						font="--base"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						{" \n\t\t\t\t"}Minta Leckét
					</Link>
					? Görgess feljebb és nézd meg
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						, vagy iratkozz fel a jegyzetekért.
					</Strong>
				</Text>
			</Box>
			<Box
				display="flex"
				width="45%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
				transition="--transformInOut"
				hover-transition="transform 0.5s ease-in-out 0.3s"
				hover-transform="scale(1.03)"
			>
				<Image
					src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/mightyframedv3.png?v=2023-12-14T13:57:02.459Z"
					display="block"
					border-radius="32px"
					title="Oktató Platformunk Illusztrációja"
					hover-transition="transform 0.5s ease-in-out 0.5s"
					srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/mightyframedv3.png?v=2023-12-14T13%3A57%3A02.459Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/mightyframedv3.png?v=2023-12-14T13%3A57%3A02.459Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/mightyframedv3.png?v=2023-12-14T13%3A57%3A02.459Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/mightyframedv3.png?v=2023-12-14T13%3A57%3A02.459Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/mightyframedv3.png?v=2023-12-14T13%3A57%3A02.459Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/mightyframedv3.png?v=2023-12-14T13%3A57%3A02.459Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/mightyframedv3.png?v=2023-12-14T13%3A57%3A02.459Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section background="linear-gradient(0deg,rgba(22, 38, 63, 0.85) 0%,--color-brandPrimary 100%) center fixed,url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/digital-bg.webp?v=2024-02-17T19:39:10.067Z) center/cover no-repeat fixed,--color-brandPrimary" color="--brandLight" quarkly-title="Syllabus">
			<Override
				slot="SectionContent"
				overflow-y="hidden"
				overflow-x="hidden"
				margin="60px auto 60px auto"
				align-items="center"
				justify-items="center"
				color="#ffd69b"
			/>
			<Box min-width="100px" min-height="100px" margin="0px auto 0px auto">
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--brandLight"
					width="100%"
					md-width="100%"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					Mit tartalmaznak a modulok?
				</Text>
				<Text
					margin="8px 0px 40px 0px"
					font="--lead"
					text-align="center"
					color="--brandLight"
					lg-max-width="720px"
					sm-text-align="left"
				>
					Az egymásra épülő modulokat az alábbi sorrendben végezhetőek el
				</Text>
			</Box>
			<Components.Accordion max-width="800px" display="grid" initOpen="3" margin="0px auto 0px auto">
				<Override slot="AccordionContent" width="100%" />
				<Override slot="ArrowStyle" />
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					transform="scale(1.01)"
					hover-transition="transform 0.5s ease-in-out 0.5s"
					sm-hover-transform="unset"
					sm-hover-transition="unset"
					background="--color-brandPrimary"
					color="--brandLight"
					pointer-events="none"
					padding="0px 5px 20px 5px"
					max-width="94%"
					margin="0px auto 0px auto"
				>
					<Box
						display="flex"
						align-items="center"
						border-width="0 0 1px 0"
						border-style="solid"
						padding="10px 0 10px 10px"
						background="rgba(72, 124, 207, 0.2)"
						hover-background="rgba(72, 124, 207, 0.4)"
						hover-transition="all 0s ease-in-out 0s"
					>
						<Icon
							category="fa"
							icon={FaUnlockAlt}
							size="28px"
							border-color="--color-brandCta"
							color="#ffd69b"
						/>
						<Text margin="0px 0px 0px 12px" font="normal 300 1.8em/1.2 --fontFamily-googleRubik">
							I. Modul{" "}
						</Text>
					</Box>
					<Text margin="16px 10px 0px 10px" font="normal 300 1.25em/1.5 --fontFamily-googleLexend">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mesterkommunikációs gondolkodásmód és alapismeretek
							<br />
						</Span>
					</Text>
					<Text margin="16px 10px 0px 10px" font="normal 300 16px/1.5 --fontFamily-googleRubik">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mire használhatod?{" "}
						</Strong>
						Képzeld el, hogy egy fontos találkozón vagy és azon múlik az előléptetésed, hogy mennyire sikerül érthetően és
				tűpontosan közvetíteni az üzeneted. Azonban már nem izgulsz úgy, mint korábban: briliáns vagy az alapokban, és
				helyén van a szemléletmódod is.
					</Text>
					<Box display="flex" align-items="center" justify-content="flex-end" margin="25px 0px 0px 0px">
						<Icon category="fa" icon={FaRegClock} size="18px" border-color="--color-brandCta" />
						<Text margin="0px 0px 0px 12px" font="--base">
							40 perc
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					transform="scale(1.01)"
					hover-transition="transform 0.5s ease-in-out 0.5s"
					sm-hover-transform="unset"
					sm-hover-transition="unset"
					background="--color-brandPrimary"
					color="--brandLight"
					pointer-events="none"
					padding="0px 5px 20px 5px"
					max-width="94%"
					margin="0px auto 0px auto"
				>
					<Box
						display="flex"
						align-items="center"
						border-width="0 0 1px 0"
						border-style="solid"
						padding="10px 0 10px 10px"
						background="rgba(72, 124, 207, 0.2)"
						hover-background="rgba(72, 124, 207, 0.4)"
						hover-transition="all 0s ease-in-out 0s"
					>
						<Icon
							category="fa"
							icon={FaUnlockAlt}
							size="28px"
							border-color="--color-brandCta"
							color="#ffd69b"
						/>
						<Text margin="0px 0px 0px 12px" font="normal 300 1.8em/1.2 --fontFamily-googleRubik">
							II. Modul{" "}
						</Text>
					</Box>
					<Text margin="16px 10px 0px 10px" font="normal 300 1.25em/1.5 --fontFamily-googleLexend">
						Nonverbális kommunikáció
					</Text>
					<Text margin="16px 10px 0px 10px" font="normal 300 16px/1.5 --fontFamily-googleRubik">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mire használhatod?{" "}
						</Strong>
						Az ügyfeled zárt testbeszédet mutat, de te már képes vagy megnyitni a tudatalattiját és bizalmat építeni
				testbeszéden keresztül (is).
					</Text>
					<Box display="flex" align-items="center" justify-content="flex-end" margin="25px 0px 0px 0px">
						<Icon category="fa" icon={FaRegClock} size="18px" border-color="--color-brandCta" />
						<Text margin="0px 0px 0px 12px" font="--base">
							40 perc
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					transform="scale(1.01)"
					hover-transition="transform 0.5s ease-in-out 0.5s"
					sm-hover-transform="unset"
					sm-hover-transition="unset"
					background="--color-brandPrimary"
					color="--brandLight"
					pointer-events="none"
					padding="0px 5px 20px 5px"
					max-width="94%"
					margin="0px auto 0px auto"
				>
					<Box
						display="flex"
						align-items="center"
						border-width="0 0 1px 0"
						border-style="solid"
						padding="10px 0 10px 10px"
						background="rgba(72, 124, 207, 0.2)"
						hover-background="rgba(72, 124, 207, 0.4)"
						hover-transition="all 0s ease-in-out 0s"
					>
						<Icon
							category="fa"
							icon={FaUnlockAlt}
							size="28px"
							border-color="--color-brandCta"
							color="#ffd69b"
						/>
						<Text margin="0px 0px 0px 12px" font="normal 300 1.8em/1.2 --fontFamily-googleRubik">
							III. Modul{" "}
						</Text>
					</Box>
					<Text margin="16px 10px 0px 10px" font="normal 300 1.25em/1.5 --fontFamily-googleLexend">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Hazugságvizsgálat
							<br />
						</Span>
					</Text>
					<Text margin="16px 10px 0px 10px" font="normal 300 16px/1.5 --fontFamily-googleRubik">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mire használhatod?{" "}
						</Strong>
						Például egy munkahelyi találkozón vagy és látod, hogy a kollégád két verbális és két nonverbális
				megtévesztéssel kapcsolatos jelet mutatott a válasza során, ami veszélyezteti a munkádat; azonban már képes
				vagy rögtön kezelni a helyzetet, nem pedig utólag.
					</Text>
					<Box
						display="flex"
						align-items="center"
						justify-content="flex-end"
						margin="25px 0px 0px 0px"
						color="--brandPrimary"
					>
						<Icon
							category="fa"
							icon={FaRegClock}
							size="18px"
							border-color="--color-brandCta"
							color="--brandLight"
						/>
						<Text margin="0px 0px 0px 12px" font="--base" color="--brandLight">
							60 perc
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					transform="scale(1.01)"
					hover-transition="transform 0.5s ease-in-out 0.5s"
					sm-hover-transform="unset"
					sm-hover-transition="unset"
					background="--color-brandPrimary"
					color="--brandLight"
					pointer-events="none"
					padding="0px 5px 20px 5px"
					max-width="94%"
					margin="0px auto 0px auto"
				>
					<Box
						display="flex"
						align-items="center"
						border-width="0 0 1px 0"
						border-style="solid"
						padding="10px 0 10px 10px"
						background="rgba(72, 124, 207, 0.2)"
						hover-background="rgba(72, 124, 207, 0.4)"
						hover-transition="all 0s ease-in-out 0s"
					>
						<Icon
							category="fa"
							icon={FaUnlockAlt}
							size="28px"
							border-color="--color-brandCta"
							color="#ffd69b"
						/>
						<Text margin="0px 0px 0px 12px" font="normal 300 1.8em/1.2 --fontFamily-googleRubik">
							IV. Modul{" "}
						</Text>
					</Box>
					<Text margin="16px 10px 0px 10px" font="normal 300 1.25em/1.5 --fontFamily-googleLexend">
						Szó szerinti technikák - asszertivitás, nemet mondás, "bottom-line", bocsánatkérés stb.
					</Text>
					<Text margin="16px 10px 0px 10px" font="normal 300 16px/1.5 --fontFamily-googleRubik">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mire használhatod?{" "}
						</Strong>
						Te is találtad már magad olyan helyzetben, ahol túl sok feladatot próbáltak rád hárítani. A tanult
				asszertivitási technikákkal már képes vagy határozottan, de udvariasan nemet mondani, megőrizve ezzel a
				teljesítményed színvonalát.
					</Text>
					<Box display="flex" align-items="center" justify-content="flex-end" margin="25px 0px 0px 0px">
						<Icon category="fa" icon={FaRegClock} size="18px" border-color="--color-brandCta" />
						<Text margin="0px 0px 0px 12px" font="--base">
							120 perc
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					transform="scale(1.01)"
					hover-transition="transform 0.5s ease-in-out 0.5s"
					sm-hover-transform="unset"
					sm-hover-transition="unset"
					background="--color-brandPrimary"
					color="--brandLight"
					pointer-events="none"
					padding="0px 5px 20px 5px"
					max-width="94%"
					margin="0px auto 0px auto"
				>
					<Box
						display="flex"
						align-items="center"
						border-width="0 0 1px 0"
						border-style="solid"
						padding="10px 0 10px 10px"
						background="rgba(72, 124, 207, 0.2)"
						hover-background="rgba(72, 124, 207, 0.4)"
						hover-transition="all 0s ease-in-out 0s"
					>
						<Icon
							category="fa"
							icon={FaUnlockAlt}
							size="28px"
							border-color="--color-brandCta"
							color="#ffd69b"
						/>
						<Text margin="0px 0px 0px 12px" font="normal 300 1.8em/1.2 --fontFamily-googleRubik">
							V. Modul{" "}
						</Text>
					</Box>
					<Text margin="16px 10px 0px 10px" font="normal 300 1.25em/1.5 --fontFamily-googleLexend">
						Szó szerinti technikák - szkriptelés - hogyan adjunk át nehéz üzeneteket egyértelműen és erőteljesen
					</Text>
					<Text margin="16px 10px 0px 10px" font="normal 300 16px/1.5 --fontFamily-googleRubik">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mire használhatod?{" "}
						</Strong>
						Például, amikor egy kényes munkahelyi problémát kell közölnöd a kollégáddal. A tanult technikák révén már
				képes vagy empatikusan és egyértelműen közvetíteni a mondanivalód, minimalizálva a félreértéseket.
					</Text>
					<Box display="flex" align-items="center" justify-content="flex-end" margin="25px 0px 0px 0px">
						<Icon category="fa" icon={FaRegClock} size="18px" border-color="--color-brandCta" />
						<Text margin="0px 0px 0px 12px" font="--base">
							60 perc
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					transform="scale(1.01)"
					hover-transition="transform 0.5s ease-in-out 0.5s"
					sm-hover-transform="unset"
					sm-hover-transition="unset"
					background="--color-brandPrimary"
					color="--brandLight"
					pointer-events="none"
					padding="0px 5px 20px 5px"
					max-width="94%"
					margin="0px auto 0px auto"
				>
					<Box
						display="flex"
						align-items="center"
						border-width="0 0 1px 0"
						border-style="solid"
						padding="10px 0 10px 10px"
						background="rgba(72, 124, 207, 0.2)"
						hover-background="rgba(72, 124, 207, 0.4)"
						hover-transition="all 0s ease-in-out 0s"
					>
						<Icon
							category="fa"
							icon={FaUnlockAlt}
							size="28px"
							border-color="--color-brandCta"
							color="#ffd69b"
						/>
						<Text margin="0px 0px 0px 12px" font="normal 300 1.8em/1.2 --fontFamily-googleRubik">
							VI. Modul{" "}
						</Text>
					</Box>
					<Text margin="16px 10px 0px 10px" font="normal 300 1.25em/1.5 --fontFamily-googleLexend">
						Szó szerinti technikák - megtört lemez, anti-megszakító, visszajelzés, tisztázás stb.
					</Text>
					<Text margin="16px 10px 0px 10px" font="normal 300 16px/1.5 --fontFamily-googleRubik">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mire használhatod?{" "}
						</Strong>
						Egy munkahelyi vitában találod magad, ahol a kollégád állandóan félbeszakít. A tanultak segítségével sikeresen
				veszed vissza magadhoz a szót, és eléred hogy figyeljen rád.
					</Text>
					<Box display="flex" align-items="center" justify-content="flex-end" margin="25px 0px 0px 0px">
						<Icon category="fa" icon={FaRegClock} size="18px" border-color="--color-brandCta" />
						<Text margin="0px 0px 0px 12px" font="--base">
							60 perc
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					transform="scale(1.01)"
					hover-transition="transform 0.5s ease-in-out 0.5s"
					sm-hover-transform="unset"
					sm-hover-transition="unset"
					background="--color-brandPrimary"
					color="--brandLight"
					pointer-events="none"
					padding="0px 5px 20px 5px"
					max-width="94%"
					margin="0px auto 0px auto"
				>
					<Box
						display="flex"
						align-items="center"
						border-width="0 0 1px 0"
						border-style="solid"
						padding="10px 0 10px 10px"
						background="rgba(72, 124, 207, 0.2)"
						hover-background="rgba(72, 124, 207, 0.4)"
						hover-transition="all 0s ease-in-out 0s"
					>
						<Icon
							category="fa"
							icon={FaUnlockAlt}
							size="28px"
							border-color="--color-brandCta"
							color="#ffd69b"
						/>
						<Text margin="0px 0px 0px 12px" font="normal 300 1.8em/1.2 --fontFamily-googleRubik">
							VII. Modul{" "}
						</Text>
					</Box>
					<Text margin="16px 10px 0px 10px" font="normal 300 1.25em/1.5 --fontFamily-googleLexend">
						Taktikai kérdezéstechnika
					</Text>
					<Text margin="16px 10px 0px 10px" font="normal 300 16px/1.5 --fontFamily-googleRubik">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mire használhatod?{" "}
						</Strong>
						Például, egy kollégád passzív-agresszív magatartása során már tudod, miként kell taktikai kérdéseket feltenni,
				hogy te irányítsd a beszélgetést, hatékonyan kezeld a konfliktust nyugodt légkörért.
					</Text>
					<Box display="flex" align-items="center" justify-content="flex-end" margin="25px 0px 0px 0px">
						<Icon category="fa" icon={FaRegClock} size="18px" border-color="--color-brandCta" />
						<Text margin="0px 0px 0px 12px" font="--base">
							120 perc
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					transform="scale(1.01)"
					hover-transition="transform 0.5s ease-in-out 0.5s"
					sm-hover-transform="unset"
					sm-hover-transition="unset"
					background="--color-brandPrimary"
					color="--brandLight"
					pointer-events="none"
					padding="0px 5px 20px 5px"
					max-width="94%"
					margin="0px auto 0px auto"
				>
					<Box
						display="flex"
						align-items="center"
						border-width="0 0 1px 0"
						border-style="solid"
						padding="10px 0 10px 10px"
						background="rgba(72, 124, 207, 0.2)"
						hover-background="rgba(72, 124, 207, 0.4)"
						hover-transition="all 0s ease-in-out 0s"
					>
						<Icon
							category="fa"
							icon={FaGift}
							size="28px"
							border-color="--color-brandCta"
							color="--brandCta"
							background="--color-brandPrimary"
							border-radius="5px"
						/>
						<Text margin="0px 0px 0px 12px" font="normal 300 1.8em/1.2 --fontFamily-googleRubik">
							Ajándék modul I.{" "}
						</Text>
					</Box>
					<Text margin="16px 10px 0px 10px" font="normal 300 1.25em/1.5 --fontFamily-googleLexend">
						Toxikus emberek kiszűrése
					</Text>
					<Text margin="16px 10px 0px 10px" font="normal 300 16px/1.5 --fontFamily-googleRubik">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mire használhatod?{" "}
						</Strong>
						Gyakran előfordulhat, hogy munkatársaid között mérgező viselkedés jelenik meg, ami stresszessé és/vagy
				elviselhetetlenné teszi a munkakörnyezetet. A tanultak segítségével kezelni tudod ezeket a káros
				viselkedésmintákat, hogy megóvd saját magad és kollégáid mentális egészségét.
					</Text>
					<Box display="flex" align-items="center" justify-content="flex-end" margin="25px 0px 0px 0px">
						<Icon category="fa" icon={FaRegClock} size="18px" border-color="--color-brandCta" />
						<Text margin="0px 0px 0px 12px" font="--base">
							120 perc
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					transform="scale(1.01)"
					hover-transition="transform 0.5s ease-in-out 0.5s"
					sm-hover-transform="unset"
					sm-hover-transition="unset"
					background="--color-brandPrimary"
					color="--brandLight"
					pointer-events="none"
					padding="0px 5px 20px 5px"
					max-width="94%"
					margin="0px auto 0px auto"
				>
					<Box
						display="flex"
						align-items="center"
						border-width="0 0 1px 0"
						border-style="solid"
						padding="10px 0 10px 10px"
						background="rgba(72, 124, 207, 0.2)"
						hover-background="rgba(72, 124, 207, 0.4)"
						hover-transition="all 0s ease-in-out 0s"
					>
						<Icon
							category="fa"
							icon={FaGift}
							size="28px"
							border-color="--color-brandCta"
							color="--brandCta"
							background="--color-brandPrimary"
							border-radius="5px"
						/>
						<Text margin="0px 0px 0px 12px" font="normal 300 1.8em/1.2 --fontFamily-googleRubik">
							Ajándék modul II.
						</Text>
					</Box>
					<Text margin="16px 10px 0px 10px" font="normal 300 1.25em/1.5 --fontFamily-googleLexend">
						Konfliktuskezelés befolyásolás technikával
					</Text>
					<Text margin="16px 10px 0px 10px" font="normal 300 16px/1.5 --fontFamily-googleRubik">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mire használhatod?{" "}
						</Strong>
						Egy feszült munkahelyi helyzetben találod magad, ahol az indulatok magasba szöknek. A tanult négy lépéses
				technikával már képes vagy előrelátni és hatásosan enyhíteni az agressziót, fenntartva ezzel a biztonságos és
				produktív munkakörnyezetet.
					</Text>
					<Box display="flex" align-items="center" justify-content="flex-end" margin="25px 0px 0px 0px">
						<Icon category="fa" icon={FaRegClock} size="18px" border-color="--color-brandCta" />
						<Text margin="0px 0px 0px 12px" font="--base">
							60 perc
						</Text>
					</Box>
				</Box>
			</Components.Accordion>
		</Section>
		<Section padding="80px 0 80px 0" background="linear-gradient(0deg,rgba(0,0,0,0) 0%,--color-brandSecondary 0%,rgba(0,0,0,0) 18.6%,--color-brandSecondary 94.8%),url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/group-diverse-business-people.webp?v=2024-02-16T20:38:07.607Z) 0% 0%/cover fixed" sm-padding="60px 0 60px 0" quarkly-title="Tűnj ki">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="flex-start"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--brandLight"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Tűnj ki a tömegből
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--brandLight"
					md-text-align="center"
					text-align="left"
				>
					A kommunikáció az egyik legkritikusabb képesség, ami a különbséget jelenti. Legyél akár szülő, irodista, vagy
			értékesítő stb., a siker mindig az emberi kapcsolataink minőségére és hatékonyságára vezethető vissza. Ez a
			program sorra veszi a kipróbált és gyakorlatban is bizonyított taktikákat, hogy lépésről lépésre szerezz
			kommunikációs szuperképességeket.
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px 4%"
				md-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					flex-direction="column"
					display="grid"
					justify-items="center"
					align-content="start"
					opacity="0.9"
				>
					<Icon
						category="gi"
						icon={GiLightProjector}
						margin="0px 0px 30px 0px"
						color="--brandSecondary"
						size="48px"
						opacity="0.8"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						<Strong>
							Tűnj ki munkatársaid közül
						</Strong>
						{" "}és segítsd a karriered azáltal, hogy tudatosan alakítod a kommunikációdat kipróbált
				modellekkel és technikákkal. Nincs mellébeszélés, csak részletes útmutató mindenről, amire szükséged van.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					flex-direction="column"
					display="grid"
					justify-items="center"
					align-content="start"
					opacity="0.9"
				>
					<Icon
						category="fa"
						icon={FaUserTie}
						margin="0px 0px 30px 0px"
						color="--brandSecondary"
						size="48px"
						opacity="0.8"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong>
								Tanulj meg
							</Strong>
							{" "}elegánsan és
						</Span>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}természetes magabiztossággal kommunikálni
						</Strong>
						, hogy érveidet világosan és meggyőzően
				közvetítsd. Legyen szó prezentációról, értekezletről vagy informális beszélgetésről, légy mindig a helyzet
				magabiztos ura.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					flex-direction="column"
					display="grid"
					justify-items="center"
					align-content="start"
					opacity="0.9"
				>
					<Icon
						category="fa"
						icon={FaUserFriends}
						margin="0px 0px 30px 0px"
						color="--brandSecondary"
						size="48px"
						opacity="0.8"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						<Strong>
							Használj kommunikációs stratégiákat
						</Strong>
						, amelyekkel átveheted az kontrollt a toxikus beszélgetésekben. Legyél te az,
				aki irányítja a beszélgetést, nem pedig az, aki csak reagál rá.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					flex-direction="column"
					display="grid"
					justify-items="center"
					align-content="start"
					opacity="0.9"
				>
					<Icon
						category="io"
						icon={IoIosChatbubbles}
						margin="0px 0px 30px 0px"
						color="--brandSecondary"
						size="48px"
						opacity="0.8"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Sajátíts el olyan technikákat, amelyekkel
						<Strong>
							{" "}könnyedén közvetíted az üzeneteidet
						</Strong>
						{" "}és elegánsan tudod képviselni
				érdekeidet a munkahelyen és a magánéletben egyaránt.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					flex-direction="column"
					display="grid"
					justify-items="center"
					align-content="start"
					opacity="0.9"
				>
					<Icon
						category="fa"
						icon={FaHandshake}
						margin="0px 0px 30px 0px"
						color="--brandSecondary"
						size="48px"
						opacity="0.8"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						<Strong>
							Fejleszd az érzelmi intelligenciád
						</Strong>
						, hogy mélyebb kapcsolatokat építhess ki munkatársaiddal és ügyfeleiddel.
				Erősítsd szociális hálódat és építs ki megbízható, tartós kapcsolatokat, amelyek elősegítik a közös sikereket.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					flex-direction="column"
					display="grid"
					justify-items="center"
					align-content="start"
					opacity="0.9"
				>
					<Icon
						category="fa"
						icon={FaChartLine}
						margin="0px 0px 30px 0px"
						color="--brandSecondary"
						size="48px"
						opacity="0.8"
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						<Strong>
							Fogadd konstruktívan és ne reagálj, hanem válaszolj
						</Strong>
						{" "}“egészségesen” a kritikákra. Adj professzionális
				visszajelzést, amivel elősegíted magad és mások a személyes fejlődését is.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			lg-padding="45px 0 45px 0"
			quarkly-title="Garancia"
			background="--color-brandSecondary"
			overflow-x="hidden"
			overflow-y="hidden"
			display="flex"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
			>
				<Text
					font="--lead"
					text-align="center"
					lg-max-width="720px"
					sm-text-align="left"
					opacity="0.8"
					color="--brandLight"
					sm-font="--base"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						100% elégedettségi garanciát vállalunk.
						<br />
					</Strong>
					A vásárlást követő 14 napig, amennyiben nem vagy elégedett,
			kérdés nélkül visszatérítjük a tanfolyam árát.
				</Text>
			</Box>
		</Section>
		<Section
			sm-padding="40px 0"
			sm-min-height="auto"
			background="linear-gradient(0deg,--color-brandLight 0%,rgba(240, 240, 240, 0.51) 50.5%,--color-brandLight 100%),url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/stairs-bg.webp?v=2024-02-16T22:03:33.219Z) 0% 0%/100vw 100% no-repeat,#d5d5d5"
			quarkly-title="Next step"
			overflow-x="hidden"
			overflow-y="hidden"
			padding="60px 0 60px 0"
			color="#f0f0f0"
			display="flex"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--brandCta"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
				>
					Tedd meg az első lépést!
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="1fr"
				grid-gap="32px"
				lg-grid-template-columns="repeat(3, 1fr)"
				lg-grid-gap="48px"
				md-grid-template-columns="repeat(3, 1fr)"
				sm-grid-template-columns="1fr"
				lg-display="flex"
				lg-justify-content="center"
			>
				<Box display="flex" align-items="center" justify-content="flex-start" flex-direction="column">
					<Icon
						category="fa"
						icon={FaUserGraduate}
						size="42px"
						color="--light"
						background="--color-brandSecondary"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--brandCta" margin="0px auto 6px auto" padding="0px 0px 30px 0px">
							LEHETŐSÉG
						</Text>
						<Text
							margin="0px 0px 24px 0px"
							font="--lead"
							color="--brandPrimary"
							opacity="0.8"
							text-align="center"
							md-text-align="left"
						>
							Kattints az alábbi gombra most, és csatlakozz a programhoz.
							<br />
							<br />
							Hozz hosszan tartó pozitív változást életedbe egy családi ebéd áráért.{" "}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			quarkly-title="CTA-Section"
			overflow-x="hidden"
			overflow-y="hidden"
			padding="60px 0 60px 0"
			border-width="0 0 3px 0"
			border-style="dashed"
			border-color="rgba(22, 38, 63, 0.1)"
		>
			<Box min-width="100px" min-height="100px" align-self="center">
				<Text
					margin="0px 0px 0px 0px"
					text-align="center"
					text-decoration="line-through"
					font="--base"
					display="inline"
				>
					57,920Ft
				</Text>
				<Text margin="0px 0px 0px 0px" text-align="center" font="--base" display="inline">
					helyett,
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					text-align="center"
					font="normal 400 26px/1.5 --fontFamily-googleRubik"
					display="inline"
					padding="15px 15px 15px 15px"
					color="--brandCta"
				>
					<Strong>
						-50%
					</Strong>
				</Text>
				<Text margin="0px 0px 0px 0px" text-align="center" font="normal 400 2em/1.5 --fontFamily-googleLexend" color="--brandSecondary">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						28,960Ft
					</Strong>
				</Text>
			</Box>
			<Link
				href="https://shop.sziklaybence.com/cart/add?items[][id]=48748819185991&items[][quantity]=1&return_to=/checkout"
				padding="12px 24px 12px 24px"
				color="--brandLight"
				background="--color-brandCta"
				text-decoration-line="initial"
				font="--lead"
				border-radius="8px"
				margin="0px auto 25px auto"
				transition="background-color 0.2s ease-in-out 0s"
				hover-background="--color-orange"
				sm-text-align="center"
				hover-transition="transform 0.5s ease-in-out 0.3s,background-color 0.2s ease-in-out 0s"
				text-align="center"
				align-self="center"
				hover-transform="scale(1.2)"
			>
				Beruházok a fejlődésembe!
			</Link>
		</Section>
		<Section padding="60px 0 60px 0" quarkly-title="Testimonies">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 56px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				opacity="0.8"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--brandSecondary"
					font="--headline2"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Rólunk mondták
				</Text>
			</Box>
			<Components.CarouselW slideInterval="8000">
				<Override slot="Slides" sm-margin="0px -6px 0px 0px" />
				<Override slot="PrevIcon" />
				<Override slot="NextIcon" />
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box display="flex" margin="0px 0px -20px 0px">
						<Text margin="0px 0px 0px 0px" font="normal 400 15px/1.5 --fontFamily-googleRubik" color="--brandSecondary" opacity="0.8">
							ELLENŐRZÖTT VÁSÁRLÁS
						</Text>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08:58:54.702Z"
							display="block"
							width="40px"
							margin="0px 0px 0px 15px"
							opacity="0.8"
							top="auto"
							right="auto"
							bottom="auto"
							height="40px"
							left="22px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17:24:40.824Z"
						margin="0px 0px 33px 0px"
						sm-margin="23px 0px 33px 0px"
						max-width="110px"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--quote"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						“A technikák valóban segítettek jobban érthetővé tenni az üzeneteimet a munkahelyen. Kicsit nehéz volt
				elsajátítani az összeset, de a kitartás meghozta gyümölcsét. Most már sokkal magabiztosabban kommunikálok.”
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/makuta-peter.jpg?v=2024-01-22T19:52:38.438Z"
							border-radius="50%"
							margin="0px 15px 12px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/makuta-peter.jpg?v=2024-01-22T19%3A52%3A38.438Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/makuta-peter.jpg?v=2024-01-22T19%3A52%3A38.438Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/makuta-peter.jpg?v=2024-01-22T19%3A52%3A38.438Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/makuta-peter.jpg?v=2024-01-22T19%3A52%3A38.438Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/makuta-peter.jpg?v=2024-01-22T19%3A52%3A38.438Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/makuta-peter.jpg?v=2024-01-22T19%3A52%3A38.438Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/makuta-peter.jpg?v=2024-01-22T19%3A52%3A38.438Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Makuta Péter
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Építőipari projektvezető
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box display="flex" margin="0px 0px -20px 0px">
						<Text margin="0px 0px 0px 0px" font="normal 400 15px/1.5 --fontFamily-googleRubik" color="--brandSecondary" opacity="0.8">
							ELLENŐRZÖTT VÁSÁRLÁS
						</Text>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08:58:54.702Z"
							display="block"
							width="40px"
							margin="0px 0px 0px 15px"
							opacity="0.8"
							top="auto"
							right="auto"
							bottom="auto"
							height="40px"
							left="22px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17:24:40.824Z"
						margin="0px 0px 33px 0px"
						sm-margin="23px 0px 33px 0px"
						max-width="110px"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--quote"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						“A nonverbális kommunikációs modul különösen hasznos volt számomra. Megtanultam, hogyan olvassak a sorok között,
				látni azt, hogy mikor stresszes a másik. Bár néhány része túl elméletinek tűnt, a gyakorlati alkalmazásuk
				kárpótolt.”
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/fodor-vivien.jpg?v=2024-01-22T19:53:05.201Z"
							border-radius="50%"
							margin="0px 15px 12px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/fodor-vivien.jpg?v=2024-01-22T19%3A53%3A05.201Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/fodor-vivien.jpg?v=2024-01-22T19%3A53%3A05.201Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/fodor-vivien.jpg?v=2024-01-22T19%3A53%3A05.201Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/fodor-vivien.jpg?v=2024-01-22T19%3A53%3A05.201Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/fodor-vivien.jpg?v=2024-01-22T19%3A53%3A05.201Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/fodor-vivien.jpg?v=2024-01-22T19%3A53%3A05.201Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/fodor-vivien.jpg?v=2024-01-22T19%3A53%3A05.201Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Fodor Vivien
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Biokémikus
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box display="flex" margin="0px 0px -20px 0px">
						<Text margin="0px 0px 0px 0px" font="normal 400 15px/1.5 --fontFamily-googleRubik" color="--brandSecondary" opacity="0.8">
							ELLENŐRZÖTT VÁSÁRLÁS
						</Text>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08:58:54.702Z"
							display="block"
							width="40px"
							margin="0px 0px 0px 15px"
							opacity="0.8"
							top="auto"
							right="auto"
							bottom="auto"
							height="40px"
							left="22px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17:24:40.824Z"
						margin="0px 0px 33px 0px"
						sm-margin="23px 0px 33px 0px"
						max-width="110px"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--quote"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						“Csodálatos élmény volt! A tanfolyam minden modulja tele volt hasznos információkkal és technikákkal, amelyeket
				azonnal alkalmazni tudtam a munkahelyi kommunikációmban.”
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/tamcsu-erika.jpg?v=2024-01-22T19:54:09.092Z"
							border-radius="50%"
							margin="0px 15px 12px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tamcsu-erika.jpg?v=2024-01-22T19%3A54%3A09.092Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tamcsu-erika.jpg?v=2024-01-22T19%3A54%3A09.092Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tamcsu-erika.jpg?v=2024-01-22T19%3A54%3A09.092Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tamcsu-erika.jpg?v=2024-01-22T19%3A54%3A09.092Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tamcsu-erika.jpg?v=2024-01-22T19%3A54%3A09.092Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tamcsu-erika.jpg?v=2024-01-22T19%3A54%3A09.092Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tamcsu-erika.jpg?v=2024-01-22T19%3A54%3A09.092Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Tamcsu Erika
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Account manager
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box display="flex" margin="0px 0px -20px 0px">
						<Text margin="0px 0px 0px 0px" font="normal 400 15px/1.5 --fontFamily-googleRubik" color="--brandSecondary" opacity="0.8">
							ELLENŐRZÖTT VÁSÁRLÁS
						</Text>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08:58:54.702Z"
							display="block"
							width="40px"
							margin="0px 0px 0px 15px"
							opacity="0.8"
							top="auto"
							right="auto"
							bottom="auto"
							height="40px"
							left="22px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17:24:40.824Z"
						margin="0px 0px 33px 0px"
						sm-margin="23px 0px 33px 0px"
						max-width="110px"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--quote"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						“Először nem voltam biztos benne, hogy a tanfolyam megéri-e az árát, de a konkrét taktikák, amelyeket tanultam,
				valóban javították a kommunikációs képességeimet. Különösen a “szkriptek” tetszettek a legjobban.”
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/szondi-akos.jpg?v=2024-01-22T19:54:26.101Z"
							border-radius="50%"
							margin="0px 15px 12px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/szondi-akos.jpg?v=2024-01-22T19%3A54%3A26.101Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/szondi-akos.jpg?v=2024-01-22T19%3A54%3A26.101Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/szondi-akos.jpg?v=2024-01-22T19%3A54%3A26.101Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/szondi-akos.jpg?v=2024-01-22T19%3A54%3A26.101Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/szondi-akos.jpg?v=2024-01-22T19%3A54%3A26.101Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/szondi-akos.jpg?v=2024-01-22T19%3A54%3A26.101Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/szondi-akos.jpg?v=2024-01-22T19%3A54%3A26.101Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Szondi Ákos
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Tartalomkészítő
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box display="flex" margin="0px 0px -20px 0px">
						<Text margin="0px 0px 0px 0px" font="normal 400 15px/1.5 --fontFamily-googleRubik" color="--brandSecondary" opacity="0.8">
							ELLENŐRZÖTT VÁSÁRLÁS
						</Text>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08:58:54.702Z"
							display="block"
							width="40px"
							margin="0px 0px 0px 15px"
							opacity="0.8"
							top="auto"
							right="auto"
							bottom="auto"
							height="40px"
							left="22px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17:24:40.824Z"
						margin="0px 0px 33px 0px"
						sm-margin="23px 0px 33px 0px"
						max-width="110px"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--quote"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						“Az asszertivitás modul különösen tetszett, mert megtanított, hogyan képviseljem magam anélkül, hogy
				agresszívnak tűnnék és bánatnám a másikat. Szuper az anyag, csak ajánlani tudom!”
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/havattoi-gergo.jpg?v=2024-01-22T19:54:26.097Z"
							border-radius="50%"
							margin="0px 15px 12px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/havattoi-gergo.jpg?v=2024-01-22T19%3A54%3A26.097Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/havattoi-gergo.jpg?v=2024-01-22T19%3A54%3A26.097Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/havattoi-gergo.jpg?v=2024-01-22T19%3A54%3A26.097Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/havattoi-gergo.jpg?v=2024-01-22T19%3A54%3A26.097Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/havattoi-gergo.jpg?v=2024-01-22T19%3A54%3A26.097Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/havattoi-gergo.jpg?v=2024-01-22T19%3A54%3A26.097Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/havattoi-gergo.jpg?v=2024-01-22T19%3A54%3A26.097Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Havattői Gergő
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Építészmérnök
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box display="flex" margin="0px 0px -20px 0px">
						<Text margin="0px 0px 0px 0px" font="normal 400 15px/1.5 --fontFamily-googleRubik" color="--brandSecondary" opacity="0.8">
							ELLENŐRZÖTT VÁSÁRLÁS
						</Text>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08:58:54.702Z"
							display="block"
							width="40px"
							margin="0px 0px 0px 15px"
							opacity="0.8"
							top="auto"
							right="auto"
							bottom="auto"
							height="40px"
							left="22px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17:24:40.824Z"
						margin="0px 0px 33px 0px"
						sm-margin="23px 0px 33px 0px"
						max-width="110px"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--quote"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						“A TV-ben láttam Bencét először, aztán a tanfolyam moduljai kíváncsivá tettek. Meglepő módon már az első leckék
				után észrevettem, hogy jobban kezelem a munkahelyi vitákat, mégsem kommunikálok agresszívan. Különösen a
				hazugságvizsgálati technikák váltak be, nagyon durva! Most is köszi Bence!”
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/lehner-gabor.jpg?v=2024-01-22T19:54:26.100Z"
							border-radius="50%"
							margin="0px 15px 12px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/lehner-gabor.jpg?v=2024-01-22T19%3A54%3A26.100Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/lehner-gabor.jpg?v=2024-01-22T19%3A54%3A26.100Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/lehner-gabor.jpg?v=2024-01-22T19%3A54%3A26.100Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/lehner-gabor.jpg?v=2024-01-22T19%3A54%3A26.100Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/lehner-gabor.jpg?v=2024-01-22T19%3A54%3A26.100Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/lehner-gabor.jpg?v=2024-01-22T19%3A54%3A26.100Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/lehner-gabor.jpg?v=2024-01-22T19%3A54%3A26.100Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Léhner Gábor
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Tőzsdei kereskedő
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box display="flex" margin="0px 0px -20px 0px">
						<Text margin="0px 0px 0px 0px" font="normal 400 15px/1.5 --fontFamily-googleRubik" color="--brandSecondary" opacity="0.8">
							ELLENŐRZÖTT VÁSÁRLÁS
						</Text>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08:58:54.702Z"
							display="block"
							width="40px"
							margin="0px 0px 0px 15px"
							opacity="0.8"
							top="auto"
							right="auto"
							bottom="auto"
							height="40px"
							left="22px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17:24:40.824Z"
						margin="0px 0px 33px 0px"
						sm-margin="23px 0px 33px 0px"
						max-width="110px"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--quote"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						"Először szkeptikus voltam, de úgy döntöttem, hogy kipróbálom a díjmentes demó leckét. Érdekes módon, most nem
				kellett csalódjak az internet ígéreteiben! Bár gyakorolni kell, amiket mondasz, a tanult technikák tényleg
				hasznosak voltak, ezért végül megvettem a teljes kurzust. Azóta a kommunikációm a kollégák és a párom szerint is
				nagyon sokat javult, de én is érzem a változást."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/kovacs-laszlo.jpg?v=2024-02-02T13:06:27.000Z"
							border-radius="50%"
							margin="0px 15px 12px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovacs-laszlo.jpg?v=2024-02-02T13%3A06%3A27.000Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovacs-laszlo.jpg?v=2024-02-02T13%3A06%3A27.000Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovacs-laszlo.jpg?v=2024-02-02T13%3A06%3A27.000Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovacs-laszlo.jpg?v=2024-02-02T13%3A06%3A27.000Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovacs-laszlo.jpg?v=2024-02-02T13%3A06%3A27.000Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovacs-laszlo.jpg?v=2024-02-02T13%3A06%3A27.000Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovacs-laszlo.jpg?v=2024-02-02T13%3A06%3A27.000Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Kovács László
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Sommelier
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box display="flex" margin="0px 0px -20px 0px">
						<Text margin="0px 0px 0px 0px" font="normal 400 15px/1.5 --fontFamily-googleRubik" color="--brandSecondary" opacity="0.8">
							ELLENŐRZÖTT VÁSÁRLÁS
						</Text>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08:58:54.702Z"
							display="block"
							width="40px"
							margin="0px 0px 0px 15px"
							opacity="0.8"
							top="auto"
							right="auto"
							bottom="auto"
							height="40px"
							left="22px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17:24:40.824Z"
						margin="0px 0px 33px 0px"
						sm-margin="23px 0px 33px 0px"
						max-width="110px"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--quote"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						"Az elégedettségi garancia miatt döntöttem a kurzus mellett, hiszen semmit sem veszíthettem. Nem kellett élnem a
				visszatérítési lehetőséggel – a tanfolyam tényleg azt hozta, amit ígért. Ez ritka a mai világban. Köszi Bence!"
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/kovats-viktor.jpg?v=2024-01-22T19:54:26.103Z"
							border-radius="50%"
							margin="0px 15px 12px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovats-viktor.jpg?v=2024-01-22T19%3A54%3A26.103Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovats-viktor.jpg?v=2024-01-22T19%3A54%3A26.103Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovats-viktor.jpg?v=2024-01-22T19%3A54%3A26.103Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovats-viktor.jpg?v=2024-01-22T19%3A54%3A26.103Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovats-viktor.jpg?v=2024-01-22T19%3A54%3A26.103Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovats-viktor.jpg?v=2024-01-22T19%3A54%3A26.103Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/kovats-viktor.jpg?v=2024-01-22T19%3A54%3A26.103Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Kováts Viktor
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Cégvezető
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box display="flex" margin="0px 0px -20px 0px">
						<Text margin="0px 0px 0px 0px" font="normal 400 15px/1.5 --fontFamily-googleRubik" color="--brandSecondary" opacity="0.8">
							ELLENŐRZÖTT VÁSÁRLÁS
						</Text>
						<Image
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08:58:54.702Z"
							display="block"
							width="40px"
							margin="0px 0px 0px 15px"
							opacity="0.8"
							top="auto"
							right="auto"
							bottom="auto"
							height="40px"
							left="22px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/approved_ico.png?v=2024-02-06T08%3A58%3A54.702Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Box>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17:24:40.824Z"
						margin="0px 0px 33px 0px"
						max-width="110px"
						sm-margin="23px 0px 33px 0px"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/stars.png?v=2024-01-11T17%3A24%3A40.824Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 35px 0px"
						color="--dark"
						font="--quote"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						"Tiktokon találtam Bencére és ez a kurzus igazi változást hozott az életemben, mert tényleg különbözik az
				átlagtól. Már a stresszes szituációkat is sokkal könnyebben kezelem ezekkel a “katonai” szintű technikákkal.
				Hálás vagyok érte, mert nagyon sok ismétlődő problémát oldott meg az életemben."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Image
							width="43px"
							height="43px"
							src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/jaszai-janos.jpg?v=2024-01-22T19:54:26.114Z"
							border-radius="50%"
							margin="0px 15px 12px 0px"
							srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/jaszai-janos.jpg?v=2024-01-22T19%3A54%3A26.114Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/jaszai-janos.jpg?v=2024-01-22T19%3A54%3A26.114Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/jaszai-janos.jpg?v=2024-01-22T19%3A54%3A26.114Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/jaszai-janos.jpg?v=2024-01-22T19%3A54%3A26.114Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/jaszai-janos.jpg?v=2024-01-22T19%3A54%3A26.114Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/jaszai-janos.jpg?v=2024-01-22T19%3A54%3A26.114Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/jaszai-janos.jpg?v=2024-01-22T19%3A54%3A26.114Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Box>
							<Text color="--dark" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Jászai János
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Fodrász
							</Text>
						</Box>
					</Box>
				</Box>
			</Components.CarouselW>
		</Section>
		<Components.FooterGlobal>
			<Override slot="link2" />
		</Components.FooterGlobal>
		<Components.QuarklycommunityKitBackToTop overflow-x="hidden" overflow-y="hidden">
			<Override slot="Icon" color="--brandCta" />
		</Components.QuarklycommunityKitBackToTop>
	</Theme>;
});